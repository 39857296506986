import React from "react";
import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../api/Api";
import { CartItemDto, OrderDto, ProdVariantParamCopyDto } from "../../../../../../../api/order/order.dto";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import LoadingSpinner from "../../../../../../components/LoadingSpinner/LoadingSpinner";
import Tile from "../../../../../../components/Tile/Tile";
import NotFoundPage from "../../not-found-page";
import BasicInfoForm from "./order-manage-form/BasicInfoForm";
import CustomerForm from "./order-manage-form/CustomerForm";
import NotepadManageForm from "./order-manage-form/NotepadManageForm";
import StatusManageForm from "./order-manage-form/StatusManageForm";
import GenericList from "src/libs/generic-list/generic-list";
import CustomerDeliveryForm from "./order-manage-form/CustomerDeliveryForm";
import PaymentGateway from "./order-manage-form/PaymentGateway";
import { splitOrderItemId } from "src/features/splitOrderItemId";
import TopLink from "src/app/components/top-link/top-link";

const calcPrice = (x?: number) => {
  if (x) return x.toFixed(2) + "zł";
  else return "wycena indywidualna";
};
const calcSumPrice = (x?: CartItemDto) => {
  if (x?.promoPriceCopy) return calcPrice(x.count * x.promoPriceCopy);
  else if (x?.priceCopy) return calcPrice(x.count * x.priceCopy);
  return "wycena indywidualna";
};

const OrdersManagePage: React.FC = () => {
  const { orderId = "" } = useParams<{ orderId: string }>();
  const { data, status } = useQuery<OrderDto, string>("order/get", [Api.Order.Get, orderId], { callOnLoad: true });

  const prodVariantName = (cartItem: CartItemDto) => cartItem.prodVariantRootName;
  const prodName = (cartItem: CartItemDto) => cartItem.productRootName;
  const tradeUnitName = (cartItem: CartItemDto) => cartItem.tradeUnitRootName;
  const paramName = (param: ProdVariantParamCopyDto) => param.colRootName;
  const paramValue = (param: ProdVariantParamCopyDto) => param.rootValue;




  return !(orderId && !Number.isNaN(orderId) && Number(orderId) > 0) ? (
    <NotFoundPage />
  ) : (
    <div className="pb-5 mb-5">
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ORDERS_LIST)} title="All orders" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ORDERS_MANAGE, { orderId: orderId })} title="Manage" isNotLink />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Order details - {orderId}</h1>
            </Tile>
          </Col>
        </PanelRow>
        {status === "loading" ? (
          <div className="text-center">
            <LoadingSpinner active={status === "loading"} />
          </div>
        ) : null}
        {data ? (
          <PanelRow>
            <Col size={6}>
              <Tile>
                <h2 className="title">Basic informations</h2>
                <hr />
                <BasicInfoForm order={data} />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Customer data</h2>
                <hr />
                <CustomerForm />
              </Tile>
              <br />
              {data && data.customerCopy && data.customerCopy.shippingAddress && data.customerCopy.isShippingAddress ? (
                <Tile>
                  <h2 className="title d-flex" id="second-delivery-address">
                    Delivery address{" "}
                    <span className="d-inline-block ml-auto" title="Customer has set a different delivery address">
                      ⚠️
                    </span>
                  </h2>
                  <hr />
                  <CustomerDeliveryForm data={data} />
                </Tile>
              ) : null}
            </Col>
            <Col size={6}>
              <Tile>
                <h2 className="title">Status management</h2>
                <hr />
                <StatusManageForm order={data} />
                <br />
                <br />
                <PaymentGateway />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Order list</h2>
                <hr />
                <GenericList
                  data={data?.cartItems}
                  // rowClassName={(x: OrderDto) => (x.isActive && !x.deletedDate ? "" : "inactive")}
                  cols={[
                    ["Lp", "id", 1, x => splitOrderItemId(x).first],
                    ["Id", "id", 2, x => splitOrderItemId(x).rest],
                    [
                      "Details",
                      "",
                      5,
                      (cartItem: CartItemDto) => (
                        <p key={cartItem.id} className="py-1">
                          <TopLink to={generatePath(RoutePath.PANEL_ASSORTMENT_PRODUCTS_EDIT, { id: cartItem.productRootId })} className="primary">
                            <b>
                              {prodName(cartItem)}&nbsp;[{splitOrderItemId(cartItem.id).rest}]
                            </b>
                          </TopLink>
                          &nbsp;&nbsp;<b>&#215;&nbsp;&nbsp;{cartItem.count}</b>
                          <br />
                          <u>Variant</u>: {prodVariantName(cartItem)} <br />
                          <u>Trade unit</u>: {tradeUnitName(cartItem)} <br />
                          {cartItem.prodVariantParamsCopy.map((param, index) => {
                            return (
                              <>
                                <u>{paramName(param)}</u>: {paramValue(param)} {cartItem.prodVariantParamsCopy.length > index ? <br /> : null}
                              </>
                            );
                          })}
                        </p>
                      ),
                    ],
                    ["Count", "count", 1],
                    ["Price", "priceCopy", 2, (x) => (x ? calcPrice(x) : "-")],
                    ["Promo price", "promoPriceCopy", 2, (x) => (x ? calcPrice(x) : "-")],
                    ["Sum", "", 2, (x) => (x ? calcSumPrice(x) : "-")],
                  ]}
                />
              </Tile>
              <br />
              <Tile>
                <h2 className="title">Note</h2>
                <hr />
                <NotepadManageForm order={data} />
              </Tile>
            </Col>
            {/* <Col size={12}>
              <Tile>
                <h2 className="title">Kopia obiektu zamówienia</h2>
                <hr />
                <OrderCopy order={data} />
              </Tile>
            </Col> */}
          </PanelRow>
        ) : null}
      </PanelGrid>
    </div>
  );
};

export default OrdersManagePage;
